<template>
    <svg width="225" height="52" viewBox="0 0 168 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M31.8865 0.201416H3.86596C1.73364 0.201416 0.00390625 1.93115 0.00390625 4.06347V32.084C0.00390625 34.2164 1.73364 35.9461 3.86596 35.9461H31.8865C34.0189 35.9461 35.7486 34.2164 35.7486 32.084V4.06347C35.7486 1.93115 34.0189 0.201416 31.8865 0.201416ZM20.1058 9.35669H24.54V16.84C23.1107 17.4487 21.6295 17.8821 20.1058 18.1344V9.35669ZM10.7406 9.35669H15.1998V18.3386C13.6742 18.2153 12.1776 17.9148 10.7406 17.4526V9.35669ZM15.1979 26.7908H10.7387V20.3149C12.1853 20.7791 13.6742 21.0758 15.1979 21.2048V26.7908ZM24.538 26.7908H20.1039V21.0411C21.6333 20.8042 23.1204 20.3977 24.538 19.8314V26.7927V26.7908ZM24.538 18.6526C23.1358 19.2459 21.6507 19.6773 20.1039 19.9277C19.083 20.0934 18.0332 20.1801 16.9661 20.1801C16.369 20.1801 15.7796 20.1531 15.1979 20.1011C13.653 19.9624 12.1602 19.6427 10.7387 19.163V18.5967C12.166 19.0359 13.6569 19.3229 15.1979 19.4404C15.7333 19.4828 16.2727 19.504 16.8178 19.504C17.935 19.504 19.0329 19.4154 20.1039 19.2439C21.6449 18.9993 23.13 18.5832 24.538 18.0169C25.4646 17.6452 26.3602 17.2079 27.2155 16.709C27.2848 16.8535 27.3465 17.0037 27.3965 17.1559C26.4912 17.7299 25.5358 18.2327 24.538 18.6545V18.6526ZM28.5234 17.0442C28.4868 17.4121 28.4001 17.7819 28.2576 18.1421C28.2113 18.2577 28.1593 18.3714 28.1054 18.4754C28.1439 17.9129 28.0611 17.3562 27.8723 16.84C27.8222 16.6975 27.7606 16.5588 27.6932 16.422C27.4119 15.8615 26.9959 15.3645 26.472 14.9851C26.6742 15.0332 26.8765 15.0929 27.0729 15.1719C27.3657 15.2894 27.6354 15.4339 27.8819 15.6072C28.0495 15.7247 28.1863 15.8731 28.2903 16.0387C28.3674 16.1581 28.4251 16.2872 28.4656 16.422C28.5253 16.6204 28.5465 16.8304 28.5272 17.0423L28.5234 17.0442Z" fill="#0516B2"/>
  <path d="M108.326 8.33813C106.96 8.33813 105.805 9.46666 105.805 10.8324C105.805 12.1985 106.96 13.3534 108.326 13.3534C109.691 13.3534 110.847 12.1985 110.847 10.8324C110.847 9.46666 109.691 8.33813 108.326 8.33813Z" fill="#0516B2"/>
  <path d="M110.505 27.5634V14.4312H106.146V27.5634H110.505Z" fill="#0516B2"/>
  <path d="M59.7682 9.14999V27.5351H55.0922V20.4688H49.9174V27.5351H45.2168V9.14999H49.9174V15.9523H55.0922V9.14999H59.7682Z" fill="#0516B2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M61.4796 20.9963C61.4796 17.0828 64.6058 14.063 68.4926 14.063C72.3793 14.063 75.5055 17.0828 75.5055 20.9963C75.5055 24.9098 72.4061 27.9296 68.4926 27.9296C64.5793 27.9296 61.4796 24.9098 61.4796 20.9963ZM71.1444 20.9963C71.1444 19.3666 70.0158 18.2649 68.4926 18.2649C66.9693 18.2649 65.8653 19.3686 65.8653 20.9963C65.8653 22.6236 66.9939 23.7278 68.4926 23.7278C69.9913 23.7278 71.1444 22.6236 71.1444 20.9963Z" fill="#0516B2"/>
  <path d="M82.3284 27.9316C85.0088 27.9316 88.0022 26.8031 88.0022 23.5193C88.0022 20.2723 85.2488 19.6374 83.3222 19.1933C82.2853 18.9541 81.488 18.7704 81.488 18.2649C81.488 17.8723 81.856 17.6351 82.4592 17.6351C82.838 17.6351 83.161 17.7389 83.4356 17.9402C83.6943 18.1299 83.91 18.4062 84.0887 18.7636L87.765 17.1096C86.6896 14.9564 84.7184 14.063 82.355 14.063C79.6764 14.063 77.0757 15.5066 77.0757 18.4753C77.0757 21.4907 79.7707 22.186 81.6651 22.6747C82.6918 22.9395 83.4835 23.1436 83.4835 23.6236C83.4835 24.096 83.0644 24.3332 82.3019 24.3332C81.2774 24.3332 80.5945 23.8588 80.2531 22.8876L76.444 24.8052C77.4948 27.0383 79.6479 27.9316 82.3284 27.9316Z" fill="#0516B2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M104.255 20.9963C104.255 24.9362 101.601 27.9296 98.0821 27.9296C96.4015 27.9296 95.1931 27.351 94.3529 26.353V32.8143H89.9937V14.4292H94.3529V15.6376C95.1931 14.6397 96.4015 14.061 98.0821 14.061C101.601 14.061 104.255 17.0565 104.255 20.9963ZM99.8691 20.9963C99.8691 19.2892 98.7406 18.1339 97.1109 18.1339C95.4814 18.1339 94.3529 19.2892 94.3529 20.9963C94.3529 22.7035 95.4814 23.8588 97.1109 23.8588C98.7406 23.8588 99.8691 22.7035 99.8691 20.9963Z" fill="#0516B2"/>
  <path d="M118.643 18.6063V22.4152C118.643 23.6503 119.614 23.7278 121.374 23.6236V27.5638C116.016 28.2199 114.282 26.5659 114.282 22.5218V18.6083H112.313V14.4312H114.282V12.0675L118.641 10.7549V14.4312H121.372V18.6083L118.643 18.6063Z" fill="#0516B2"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M136.999 27.5634V14.4308L132.64 14.4332V15.6416C131.8 14.6437 130.591 14.065 128.911 14.065C125.392 14.065 122.738 17.0585 122.738 20.9983C122.738 24.9382 125.392 27.9316 128.911 27.9316C130.591 27.9316 131.8 27.353 132.64 26.355V27.5634H136.999ZM129.88 18.1339C131.509 18.1339 132.638 19.2892 132.638 20.9963C132.638 22.7035 131.509 23.8588 129.88 23.8588C128.25 23.8588 127.122 22.7035 127.122 20.9963C127.122 19.2892 128.25 18.1339 129.88 18.1339Z" fill="#0516B2"/>
  <path d="M139.316 8.38925H143.675V27.5634H139.316V8.38925Z" fill="#0516B2"/>
  <path d="M148.377 8.33813C147.012 8.33813 145.856 9.46666 145.856 10.8324C145.856 12.1985 147.012 13.3534 148.377 13.3534C149.743 13.3534 150.898 12.1985 150.898 10.8324C150.898 9.46666 149.743 8.33813 148.377 8.33813Z" fill="#0516B2"/>
  <path d="M150.557 27.5634V14.4312H146.198V27.5634H150.557Z" fill="#0516B2"/>
  <path d="M158.49 18.6063V22.4152C158.49 23.6503 159.461 23.7278 161.222 23.6236V27.5638C155.863 28.2199 154.129 26.5659 154.129 22.5218V18.6083H152.16V14.4312H154.129V12.0675L158.488 10.7549V14.4312H161.219V18.6083L158.49 18.6063Z" fill="#0516B2"/>
  <path d="M165.423 8.33813C164.058 8.33813 162.902 9.46666 162.902 10.8324C162.902 12.1985 164.058 13.3534 165.423 13.3534C166.789 13.3534 167.944 12.1985 167.944 10.8324C167.944 9.46666 166.789 8.33813 165.423 8.33813Z" fill="#0516B2"/>
  <path d="M167.603 27.5634V14.4312H163.244V27.5634H167.603Z" fill="#0516B2"/>
</svg>

</template>
